import(/* webpackMode: "eager" */ "/app/apps/auth/src/server/config/react.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@unleash+nextjs@1.5.0_next@14.1.4_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__rea_thyniidtwoqhp7jhb6jvuhj3fm/node_modules/@unleash/nextjs/client.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.17.2_next@14.1.4_@babel+core@7.24.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_rea_yio3baafycvdx3xv227zr2wz34/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-recaptcha-v3@1.5.2_next@14.1.4_@babel+core@7.24.9_@opentelemetry+api@1.9.0_react-dom@18._y57dm6xswtjylsvgaollp5nr4e/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-recaptcha-v3@1.5.2_next@14.1.4_@babel+core@7.24.9_@opentelemetry+api@1.9.0_react-dom@18._y57dm6xswtjylsvgaollp5nr4e/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-recaptcha-v3@1.5.2_next@14.1.4_@babel+core@7.24.9_@opentelemetry+api@1.9.0_react-dom@18._y57dm6xswtjylsvgaollp5nr4e/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-recaptcha-v3@1.5.2_next@14.1.4_@babel+core@7.24.9_@opentelemetry+api@1.9.0_react-dom@18._y57dm6xswtjylsvgaollp5nr4e/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\",\"weight\":[\"400\",\"100\",\"300\",\"500\",\"700\",\"900\"]}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "/app/packages/events/src/EventContextProvider.tsx");
import(/* webpackMode: "eager" */ "/app/packages/events/src/sdkProvider.tsx");
import(/* webpackMode: "eager" */ "/app/packages/tailwind-ui/src/components/MobileSafeArea/index.tsx");
import(/* webpackMode: "eager" */ "/app/packages/tailwind-ui/src/components/Sonner.tsx");
import(/* webpackMode: "eager" */ "/app/packages/tailwind-ui/src/styles/globals.css")