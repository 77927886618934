import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { CookiesKeys, SessionStorageKeys } from '@monorepo/interfaces';
import { useRouter } from '@monorepo/router';

const logout = async () => {
  return new Promise<{ message: string }>((resolve, reject) => {
    try {
      const cookiesConfig = { path: '/', domain: process.env.COOKIES_URL };

      Cookies.remove(CookiesKeys.AUTH, cookiesConfig);
      Cookies.remove(CookiesKeys.REFRESH, cookiesConfig);
      Cookies.remove(CookiesKeys.LAST_PATH, cookiesConfig);
      Cookies.remove(CookiesKeys.PERSON_ID, cookiesConfig);
      Cookies.remove(CookiesKeys.SCHOOL_IDS, cookiesConfig);
      Cookies.remove(CookiesKeys.APP_DISABLED, cookiesConfig);

      sessionStorage.removeItem(SessionStorageKeys.VIEWED_FIDC_MODAL);
      sessionStorage.removeItem(SessionStorageKeys.VIEWED_FIDC_APP);
      sessionStorage.removeItem(SessionStorageKeys.IMPERSONATE_STORE);

      resolve({
        message: 'Logout successfully',
      });
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      reject(err);
    }
  });
};

export const useLogout = () => {
  const router = useRouter();
  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      router.push('/auth', { useWindowOpen: true });
    },
  });
};
