import type {
  ChosenPaymentPlanMethods,
  InstallmentFilters,
  InstallmentStatus,
  InstallmentTypeV2,
  Invoice,
  NegotiationStatus,
  PaymentMethods,
  ReceivableTypeV2,
} from './installments';

export type MakeAgreementSimulationInput = {
  agreement_simulation_id?: string;
  installments_ids: string[];
  exemption_reasons?: ExemptionReasonsEnum;
  school_discount?: number;
};

export type AcceptAgreementSimulationPaymentPlan = {
  index: number;
  methods: string[];
  max_installments_credit_card?: number;
}[];

export type NegotiationFlow = 'negotiation' | 'credit_card_fee';

export type MakeContractAgreementSimulation = {
  contract_id: string;
  installments_ids: string[];
  exemption_reasons?: AgreementExemptionReasons[];
  flow?: NegotiationFlow;
};

export type ImpersonateInput = {
  impersonatedTaxId?: string;
};

export type MakeContractAgreementSimulationInput = {
  data: MakeContractAgreementSimulation[];
} & ImpersonateInput;

export type getInstallmentsInput = {} & Omit<
  InstallmentFilters,
  'guardian_ids'
> &
  ImpersonateInput;

export type MakeAgreementSimulation = {
  id: string;
  created_at: string;
  original_amount: number;
  due_date: string;
  due_amount: number;
  early_payment_discount: number;
  due_payment_discount: number;
  isaac_discount: number;
  school_discount: number;
  perpetual_discount: number;
  total_discounts: number;
  original_taxes: number;
  original_fine: number;
  original_interest: number;
  due_taxes: number;
  due_fine: number;
  due_interest: number;
  exempt_taxes: number;
  exempt_fine: number;
  exempt_interest: number;
  original_installment_ids: string[];
  resultant_installment_ids: string[];
  agreement_simulation_date: string;
  agreement_simulation_amount: number;
  exemption_reasons: AgreementExemptionReasons[];
  payment_plans: PaymentPlan[];
  policy_segment: string | null;
  policy_segment_range: number;
  channel: AgreementChannel;
  expires_at: string;
  credit_card_fee?: number;
  credit_card_fee_percentage?: number;
};

export type MakeAgreementSimulationResult = {
  contract_id: string;
  response: MakeAgreementSimulationResponse;
};

export type MakeAgreementSimulationResponse = {
  data: MakeAgreementSimulation;
};

export type AcceptAgreementSimulationInput = {
  simulationId: string;
  params: AcceptAgreementSimulationParams;
  impersonatedTaxId?: string;
};

export type AcceptAgreementSimulationParams = {
  chosen_payment_plan: ChosenPaymentPlan;
  email?: string;
  due_date?: string;
};

export type AcceptAgreementSimulationResponse = {
  data: Agreement;
};

export type AcceptAgreementSimulationV2Response = {
  data: AcceptNegotiation;
};

export type ChosenPaymentPlan = {
  payment_method: ChosenPaymentPlanMethods;
  down_payment_amount: number;
  number_of_installments: number;
  credit_card_installments?: number;
};

export type InstallmentChosenPaymentPlan = {
  payment_method: PaymentMethods;
  down_payment_amount: number;
  number_of_installments: number;
};

export type ChosenPaymentPlanNegotiationV2 = {
  methods: PaymentMethods[];
  amount: number;
  max_installments_credit_card: number;
  due_date: string;
  index: number;
};

export type ChosenPaymentPlanAcceptNegotiationV2 = {
  methods: PaymentMethods[];
  max_installments_credit_card: number;
  index: number;
};

export type PaymentPlan = {
  payment_method: ChosenPaymentPlanMethods;
  min_upfront_amount: number;
  max_installments_allowed: number;
};

export enum TypeEnum {
  negotiationApi = 'negotiationApi',
  aglutination = 'agglutination',
  renegotiation = 'renegotiation',
}

export type AgreementType =
  | 'negotiationApi'
  | 'agglutination'
  | 'renegotiation'
  | 'negotiationApiV2';

export enum ChannelEnum {
  escola = 'PI-ESCOLA',
  admin = 'PI-ADMIN',
  samuca = 'SAMUCA',
}

export type AgreementChannel = 'PI-ESCOLA' | 'PI-ADMIN' | 'SAMUCA';

export type Simulation = {
  id: string;
  created_at: string;
  original_amount: number;
  due_date: string;
  due_amount: number;
  early_payment_discount: number;
  due_payment_discount: number;
  school_discount: number;
  perpetual_discount: number;
  total_discounts: number;
  original_taxes: number;
  original_fine: number;
  original_interest: number;
  due_taxes: number;
  due_fine: number;
  due_interest: number;
  exempt_taxes: number;
  exempt_fine: number;
  exempt_interest: number;
  original_installment_ids: string[] | null;
  agreement_simulation_date: string;
  agreement_simulation_amount: number;
  exemption_reasons?: AgreementExemptionReasons[];
  payment_plans: PaymentPlan[] | null;
  policy_segment: string;
  policy_segment_range: number;
  channel?: AgreementChannel;
  expires_at?: string;
};

export type Agreement = {
  id: string;
  short_id: string;
  type: AgreementType;
  early_payment_discount_applied?: number;
  due_payment_discount_applied: number;
  interest_applied: number;
  fine_applied: number;
  original_installment_ids?: string[];
  resultant_installment_ids?: string[];
  resultant_invoices_ids?: string[];
  agreement_date: string;
  agreement_amount: number;
  exemption_reasons?: AgreementExemptionReasons[];
  exempt_fine: number;
  exempt_interest: number;
  school_discount: number;
  chosen_payment_plan: InstallmentChosenPaymentPlan;
  simulation_id: string;
  simulation?: Simulation;
  agreement_installments?: string[] | null;
  result_invoices?: Invoice[];
  channel?: AgreementChannel;
};

export type Negotiation = {
  id: string;
  short_id: string;
  status: NegotiationStatus;
  type: AgreementType;
  early_payment_discount_applied?: number;
  due_payment_discount_applied: number;
  interest_applied: number;
  fine_applied: number;
  original_receivables_ids?: string[];
  resultant_receivables_ids?: string[];
  resultant_invoices_ids?: string[];
  negotiation_date: string;
  negotiation_amount: number;
  exemption_reasons?: AgreementExemptionReasons[];
  exempt_fine: number;
  exempt_interest: number;
  isaac_discount_applied: number;
  simulation_id: string;
  channel?: AgreementChannel;
  down_payment_amount?: number;
  down_payment_enabled_payment_method?: PaymentMethods[];
  down_payment_due_date?: string;
  down_payment_max_installments_credit_card?: number;
  chosen_payment_plans?: ChosenPaymentPlanNegotiationV2[];
};

export enum ExemptionReasonsEnum {
  is_angry = 'is_angry',
  extraordinary = 'extraordinary',
  incorrect_data = 'incorrect_data',
}

export type AgreementExemptionReasons =
  | 'extraordinary'
  | 'first_time_overdue'
  | 'incorrect_data'
  | 'is_angry'
  | 'is_inactive'
  | 'school_discount'
  | null;

export enum StatusEnum {
  pending = 'pending',
  confirmed = 'confirmed',
  canceled = 'canceled',
  expired = 'expired',
}

export type AgreementStatus = 'pending' | 'confirmed' | 'canceled' | 'expired';

export type NegotiationInstallment = {
  id: string;
  productName: string;
  studentName: string;
  value: number;
  originalAmount: number;
  dueDiscount: number;
  dueDate?: string;
  overdue: boolean;
  status: InstallmentStatus;
  referenceYear: number;
  type: InstallmentTypeV2;
  receivableType: ReceivableTypeV2;
  originNegotiationTag?: string;
  isAgingOver90Days?: boolean;
  isFromCreditCardFlow: boolean;
  creditCardFee: number;
};

export type NegotiationAgreementInstallment = {
  id: string;
  referenceMonth: number;
  amount: number;
  isNegotiated: boolean;
};

export type NegotiationAgreement = {
  id: string;
  shortId: string;
  index: number;
  contractId: string;
  productName: string;
  studentName: string;
  value: number;
  simulationDate?: string;
  negotiationDate?: string;
  dueDate: string;
  fine: number;
  interest: number;
  taxes: number;
  exemptFine: number;
  exemptInterest: number;
  exemptTaxes: number;
  dueDiscount: number;
  earlyDiscount: number;
  isaacDiscount: number;
  downPaymentAmount?: number;
  exemptionReasons: AgreementExemptionReasons[];
  paymentPlans: PaymentPlan[];
  installments: NegotiationAgreementInstallment[];
  payment?: PaymentData;
  downPaymentInstallmentId?: string;
  resultantInstallmentsIds?: string[];
  accepted?: boolean;
  isAgingOver90Days?: boolean;
  creditCardFee?: number;
  creditCardFeePercentage?: number;
  invoiceUrl?: string;
};

export type NegotiationList = Map<string, NegotiationInstallment[]>;
export type NegotiationAgreementMap = Map<string, NegotiationAgreement>;
export type ProposalDetailsMap = Map<string, ProposalDetails>;
export type PaymentMethodType = 'offer' | 'custom';

export type ProposalDetailsInvoice = {
  dueDate: string;
  amount: number;
};

export type ProposalDetails = {
  agreementId: string;
  agreementShortId: string | number;
  entryValue: number;
  dueDate?: string;
  numberOfInstallments: number;
  creditCardInstallments?: number;
  isBestProposal?: boolean;
  paymentMethod?: ChosenPaymentPlanMethods;
  installmentsTotalValue: number;
  invoices?: ProposalDetailsInvoice[];
  paymentMethodType?: PaymentMethodType;
};

export type SelectedInstallments = {
  totalValue: number;
  installmentIds: Set<string>;
};

export type PaymentData = {
  paymentMethod?: ChosenPaymentPlanMethods;
  numberOfInstallments?: number;
  creditCardInstallments?: number;
  entryValue?: number;
  dueDate?: string;
  //TODO: remove after remove negotiation V1
  installmentsIds?: string[];
};

export type NegotiationReceivables = {
  id: string;
  created_at: string;
  type: 'ORIGINAL' | 'RESULTANT';
  negotiation_id: string;
  receivable_id: string;
  invoice_id: string;
};

export type AcceptNegotiation = {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  agreement_simulation_id: string;
  down_payment: {
    id: string;
    created_at: string;
    invoice_url?: string;
    amount: number;
    referral?: string;
    digitable_line?: string;
    qr_code?: string;
    payment_provider: string;
    status: 'OPEN' | 'CANCELED' | 'PAID';
    due_date: string;
    paid_amount?: number;
    description: string;
    available_payment_methods: PaymentMethods[];
    invoice_id?: string;
  };
  early_payment_discount_applied: number;
  due_payment_discount_applied: number;
  isaac_discount_applied: number;
  interest_applied: number;
  fine_applied: number;
  resultant_amount: number;
  exemption_reasons?: AgreementExemptionReasons[];
  exempt_fine: number;
  exempt_interest: number;
  chosen_payment_plan: ChosenPaymentPlanAcceptNegotiationV2[];
  negotiation_receivables: NegotiationReceivables[];
  channel: AgreementChannel;
  source: string;
  status: NegotiationStatus;
  guardian_id: string;
  student_id: string;
  school_id: string;
};

export type DownPayment = {
  down_payment_id?: string;
  invoice_url?: string;
};

export type AcceptNegotiationResponse = Negotiation & DownPayment;
