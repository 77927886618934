import type { ContractsBySchoolData } from '../contracts';
import type { StudentData } from '../enrollment';

export type CacheData = {
  guardian_ids?: string[];
  students_data?: StudentData[];
  contracts_by_school_data?: ContractsBySchoolData[];
};

export enum CacheKeys {
  cacheData = '.cache_data',
}

export type UserData = {
  firstAccess: string;
  lastAccess: string;
};

export type DownPaymentBrokenPromise = {
  id: string;
  shortId: string;
  dateShowed?: string;
};

export type AdvancePaymentInstallment = {
  installmentId: string;
  nextViewDate: string;
};

export type NegotiationData = {
  downPaymentsBrokenPromise?: DownPaymentBrokenPromise[];
  advancePaymentInstallments?: AdvancePaymentInstallment[];
};

export type TeachingMaterialData = {
  viewedTeachingMaterialHomeBanner: boolean;
};

export enum IsaacPayRedisKeys {
  isaacPay = 'isaac-pay',
  teachingMaterial = 'teaching-material-data',
}

export enum NegotiationRedisKeys {
  negotiationApp = 'negotiation-app',
  creditCardIncentiveVisibility = 'credit-card-incentive-visibility',
}

export type MeuIsaacData = {
  viewedCAIntroPage?: boolean;
};

export type StudentCollectionData = {
  lastStudentConfirmationDate?: Date;
};

export enum MeuIsaacRedisKeys {
  meuIsaacApp = 'meu-isaac-app',
}

export enum StudentCollectionDataKeys {
  studentCollectionDataApp = 'student-collection-data-app',
}

export enum RedisEndpoint {
  GET_FIDC = 'getFidcData',
  GET_MEU_ISAAC = 'getMeuIsaacData',
  GET_CACHE_DATA = 'getCacheData',
  GET_STUDENT_COLLECTION_DATA = 'getStudentCollectionData',
}

export type FIDCData = {
  fidcRedirect?: boolean;
  url?: string;
  pathname?: string;
};

export enum FIDCRedisKeys {
  fidcApp = 'fidc-confirmation-app',
}

export type CreditCardIncentive = {
  totalViews: number;
  lastViewed: string;
};
